
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["input", "enter"],
  props: {
    label: String,
    name: String,
    placeholder: String,
    value: String,
    height: String
  }
})
export default class MTextArea extends Vue {
  label = "";
  name = "";
  placeholder = "";
  value = "";
  height = "normal";
}
