<template>
  <router-link
    v-if="isInternal"
    :to="path"
    class="w-full px-3 py-5 flex justify-between items-center text-gray-700 transition-all duration-300 hover:bg-gray-200"
  >
    <div class="flex items-center">
      <m-icon :type="icon" class="mr-3" />
      <p>{{ text }}</p>
    </div>
    <m-icon type="chevron-right" class="mx-2" />
  </router-link>
  <a
    v-else
    :href="path"
    target="_blank"
    rel="noopener noreferrer"
    class="w-full px-3 py-5 flex justify-between items-center text-gray-700 transition-all duration-300 hover:bg-gray-200"
  >
    <div class="flex items-center">
      <m-icon :type="icon" class="mr-3" />
      <p>{{ text }}</p>
    </div>
    <m-icon type="chevron-right" class="mx-2" />
  </a>
</template>

<script lang="ts">
import MIcon from "@/components/MIcon.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MIcon
  },
  props: {
    icon: String,
    text: String,
    path: String
  }
})
export default class MSInfoItem extends Vue {
  icon!: string;
  text!: string;
  path!: string;

  get isInternal(): boolean {
    return this.path.startsWith("/");
  }
}
</script>
