
import MIcon from "@/components/MIcon.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MIcon
  },
  props: {
    icon: String,
    text: String,
    path: String
  }
})
export default class MSInfoItem extends Vue {
  icon!: string;
  text!: string;
  path!: string;

  get isInternal(): boolean {
    return this.path.startsWith("/");
  }
}
