<template>
  <div class="w-full">
    <label :for="name" class="leading-7 text-sm font-medium text-gray-500">
      {{ label }}
    </label>
    <textarea
      :name="name"
      :placeholder="placeholder"
      :value="value"
      class="text-area-height w-full bg-white rounded-md border border-white focus:border-primary-500 text-base outline-none appearance-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-300"
      :class="{ [height]: height }"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('enter')"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["input", "enter"],
  props: {
    label: String,
    name: String,
    placeholder: String,
    value: String,
    height: String
  }
})
export default class MTextArea extends Vue {
  label = "";
  name = "";
  placeholder = "";
  value = "";
  height = "normal";
}
</script>

<style scoped lang="scss">
.text-area-height {
  &.tall {
    height: 250px;
  }
}
</style>
